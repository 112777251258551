







































































































































































































import axios, { AxiosRequestConfig, Method, ResponseType } from 'axios';
import moment from 'moment';
import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';
import UiToggle from '@/components/ui/UiToggle.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { InactivityWatcher } from '@/inactivityWatcher';
import { MUT_SNACKBAR } from '@/store';
import { requestParentToKeepActive } from '@/utils/iframeMessageRequester';

import type { PostExport } from '../types';

@Component({
  components: {
    UiDataTable,
    UiLoading,
    UiTooltip,
    UiToggle,
    UiPageHeader,
  },
})
export default class Export extends BaseVue {
  public date = '';
  public exportData = null;
  public isLoading = false;
  public exportMode = 'Transaction Lines';
  public from = '';
  public to = '';
  public exports = [];
  public loadingExports = false;
  public baseUrl = process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;
  public isNew = false;
  public walletId = 'all';
  public excludeInternal = false;
  public reportElapsedTime? = '';

  readonly headers = [
    {
      id: 'id',
      label: 'Export Id',
      defaultVisibility: true,
    },
    {
      id: 'status',
      label: 'Status',
      defaultVisibility: true,
    },
    {
      id: 'params',
      label: 'Parameters',
      defaultVisibility: true,
    },
    {
      id: 'runAt',
      label: 'Run At',
      defaultVisibility: true,
    },
    {
      id: 'download',
      label: 'Download',
      defaultVisibility: true,
    },
  ];

  public get wallets() {
    return this.$store.getters['wallets/WALLETS'];
  }

  async runExport() {
    this.isLoading = true;
    this.reportElapsedTime = undefined;

    requestParentToKeepActive('report', true);
    const inactivityWatcherKeepActive = InactivityWatcher.instance?.keepActive(
      () => this.isLoading && document.contains(this.$el)
    );
    try {
      let exportUrl = this.baseUrl + 'export/transactions?orgId=' + this.$store.state.currentOrg.id;

      if (this.from) {
        exportUrl = exportUrl + `&from=${this.from}`;
      }

      if (this.to) {
        exportUrl = exportUrl + `&to=${this.to}`;
      }

      if (this.walletId) {
        exportUrl = exportUrl + `&walletId=${this.walletId}`;
      }

      if (this.excludeInternal) {
        exportUrl = exportUrl + `&excludeInternal=${this.excludeInternal}`;
      }

      if (this.exportMode === 'Transaction Lines') {
        exportUrl = exportUrl + '&mode=TxnLine';
      }

      const data: PostExport = {
        org: this.$store.state.currentOrg.id.toString(),
      };

      const reportStartTime = Date.now();

      await this.post(exportUrl, data, 'post');

      this.reportElapsedTime = this.getElapsedTime(reportStartTime);
    } finally {
      requestParentToKeepActive('report', false);
      inactivityWatcherKeepActive?.dispose();
    }
    // pros/cons of submitFrom() method: it downloads the csv file automatically,
    // but doesnt have a way to set the this.isLoading to false

    // pros/cons of post() method: it downalods the csv file automatically,
    // and can set the this.isLoading to false after the download
    // but doesnt have access to the filename (content-disposition header) set by the server
    // it needs additional CORS settings on the backend to allow and expose that header
  }

  post(url: string, data: PostExport, method: Method): Promise<void> {
    const config: AxiosRequestConfig = {
      method,
      url,
      withCredentials: true,
      responseType: 'blob' as ResponseType,
    };

    return axios(config).then((response) => {
      const filename = `bitwave-export-${moment().format('DDMMYYYY')}.csv`;

      if (!response || response.status !== 200) {
        this.showErrorMessage();
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        link.click();
        window.URL.revokeObjectURL(url);
        this.isLoading = false;
        this.showSuccessMessage();
      }
    });
  }

  /**
   * sends a request to the specified url from a form. this will change the window location.
   * @param {string} path the path to send the post request to
   * @param {object} params the paramiters to add to the url
   * @param {string} [method=post] the method to use on the form
   */
  submitFrom(path: string, params: PostExport, method = 'post') {
    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);

    Object.keys(params).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key as keyof PostExport]);

        form.appendChild(hiddenField);
      }
    });

    document.body.appendChild(form);
    form.submit();

    // this form.submit syntax is not able to use the callback fn
    // form.submit({}, (_, resp) => {
    //   console.log('Export finished, resp: ', resp);
    //   this.isLoading = false;
    // });
  }

  showErrorMessage() {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'error',
      message: 'Failed to export. Try again later',
    });
  }

  showSuccessMessage() {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'success',
      message: 'File download completed',
    });
  }

  async loadExports(orgId: string) {
    this.loadingExports = true;
    try {
      this.exports = (
        await axios.get(`${this.baseUrl}v2/orgs/${orgId}/transactions:export`, {
          withCredentials: true,
        })
      ).data;
    } catch (e) {
      console.error('Loading exports failed', e);

      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: 'Failed to load exports',
      });
    }

    this.loadingExports = false;
  }

  async mounted() {
    // this.loadExports(this.$store.state.currentOrg.id);

    const queryParams = this.$route.query;
    if (queryParams.datawarehouse && typeof queryParams.datawarehouse === 'string') {
      this.isNew = queryParams.datawarehouse === 'true';
    }
  }

  async runExportV2(from: string, to: string, walletId: string) {
    try {
      await axios({
        method: 'post',
        url: `${this.baseUrl}v2/orgs/${this.$store.state.currentOrg.id}/transactions:export`,
        data: { from, to, walletId },
        withCredentials: true,
      });

      this.$store.commit(MUT_SNACKBAR, {
        color: 'success',
        message: 'Started export',
      });

      this.isLoading = false;

      this.loadExports(this.$store.state.currentOrg.id);
    } catch (e) {
      console.error('Running export failed', e);

      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: 'Failed to export',
      });
    }
  }

  toggleUi(val: boolean) {
    this.$emit('toggleUi', val);
    localStorage.setItem('exportsUi', val ? 'new' : 'old');
  }
}
